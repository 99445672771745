@font-face {
  font-family: 'Iran-Sans';
  src: url('../public/assets/fonts/normal/IRANSansWeb.woff2') format('woff2'),
  url('../public/assets/fonts/normal/IRANSansWeb.woff') format('woff'),
  url('../public/assets/fonts/normal/IRANSansWeb.ttf') format('truetype'),
  url('../public/assets/fonts/normal/IRANSansWeb.eot') format('eot');
  font-weight: bold;
  font-style: normal;
}

 @font-face {
  font-family: 'Iran-light';
  src: url('../public/assets/fonts/Light/IRANSansWeb_Light.woff2') format('woff2'),
  url('../public/assets/fonts/Light/IRANSansWeb_Light.woff') format('woff'),
  url('../public/assets/fonts/Light/IRANSansWeb_Light.ttf') format('truetype'),
  url('../public/assets/fonts/Light/IRANSansWeb_Light.eot') format('eot');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Iran-bold';
  src: url('../public/assets/fonts/bold/IRANSansWeb_Bold.woff2') format('woff2'),
  url('../public/assets/fonts/bold/IRANSansWeb_Bold.woff') format('woff'),
  url('../public/assets/fonts/bold/IRANSansWeb_Bold.ttf') format('truetype'),
  url('../public/assets/fonts/bold/IRANSansWeb_Bold.eot') format('eot');
  font-weight: 200;
  font-style: normal;
} 


.articles img{
  width: 100% !important;
  height: auto !important;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.articles *{

  text-align: justify;
}
html *{
  font-family: 'Iran-bold' !important;
}
.App{
  font-family: 'Iran-bold' !important;
}
.body{
  max-width:  430px;
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.searchbox{

  background-color: #3A3B3C;
  border-radius: 15px;
  height: 40px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

}
.textfield {
  border: none;
  color: white !important;
}



html *{
  size: landscape !important;

}
.articles img{
  width: 300px !important;
  height: auto !important;
}

@page { 
    size: landscape   !important;
  }
  @media print {
    @page {
      size: landscape   !important;
    }
  }